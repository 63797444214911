import * as React from "react"
import './style.scss'
import * as styles from './404.module.scss'
import Layout from '../components/layout'

// markup
const NotFoundPage = () => {
  return (
    <Layout pageTitle="Not found">
    <div className={styles.row}>
        <h1>Klauzula informacyjna o przetwarzaniu danych osobowych</h1>
        <p>Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016) zwanym dalej RODO, Administrator informuje:</p>
        <p>
        Administratorem danych osobowych zawartych w zgłoszeniu ambarassed.art jest Jakub Podmokly, dane kontaktowe Inspektora Ochrony Danych: kontakt(@)ambarassed.art. Dane osobowe są przetwarzane w celu dostarczenia newslettera, na podstawie art. 6 ust. 1 lit. a) rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 (RODO) i nie są przekazywane innym odbiorcom, organizacji międzynarodowej czy do państw trzecich. Dane osobowe przechowywane są przez okres prenumeraty. Wnioskodawca ma prawo do dostępu do swoich danych osobowych, ich sprostowania oraz wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych. Podanie danych osobowych jest dobrowolne, nie wynika z przepisów, ich niepodanie będzie skutkowało brakiem możliwości zamówienia. Na podstawie danych osobowych nie są podejmowane zautomatyzowane decyzje, w tym nie stosuje się profilowania. 
        </p>
    </div>
    </Layout>
  )
}

export default NotFoundPage
